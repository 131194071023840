import React, { useState } from 'react';
import Livechart from './livechart'; 
import Livechart2 from './livechart2'; 

const ChartSwitcher = ({ symbol }) => {
  const [showCandlestick, setShowCandlestick] = useState(false);
  const [dateRange, setDateRange] = useState('5D');

  if (!symbol) {
    return (
      <div className="bg-white dark:bg-dark-card rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-[350px] text-gray-500 dark:text-gray-400">
          <div className="text-center">
            <svg className="w-12 h-12 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" 
              />
            </svg>
            <p>Enter a stock symbol to view chart</p>
          </div>
        </div>
      </div>
    );
  }

  const timeRanges = [
    { label: '5D', value: '5D' },
    { label: '1M', value: '1M' },
    { label: '6M', value: '6M' },
    { label: 'YTD', value: 'YTD' },
    { label: '1Y', value: '1Y' },
    { label: '5Y', value: '5Y' },
    { label: 'Max', value: 'MAX' }
  ];

  const getTimeRangeButtonClass = (isSelected) => {
    return isSelected
      ? "px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 whitespace-nowrap bg-purple-600 text-white dark:bg-purple-700"
      : "px-3 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 whitespace-nowrap text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700";
  };

  const getChartTypeButtonClass = (isCandlestick) => {
    return isCandlestick
      ? "px-4 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 bg-purple-600 text-white dark:bg-purple-700 hover:bg-purple-700 dark:hover:bg-purple-800"
      : "px-4 py-1.5 rounded-md text-sm font-medium transition-colors duration-200 bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600";
  };

  return (
    <div className="bg-white dark:bg-dark-card rounded-lg shadow-lg p-6">
      <div className="flex flex-col space-y-4">

        <div className="flex flex-wrap justify-between items-center gap-4">

          <div className="flex flex-wrap gap-2">
            {timeRanges.map((range) => (
              <button
                key={range.value}
                onClick={() => setDateRange(range.value)}
                className={getTimeRangeButtonClass(dateRange === range.value)}
              >
                {range.label}
              </button>
            ))}
          </div>
          <button 
            onClick={() => setShowCandlestick(!showCandlestick)}
            className={getChartTypeButtonClass(showCandlestick)}
          >
            {showCandlestick ? 'Switch to Line Chart' : 'Switch to Candlestick'}
          </button>
        </div>
      

        <div className="bg-white dark:bg-dark-card rounded-lg overflow-hidden">
          {showCandlestick ? (
            <Livechart symbol={symbol} dateRange={dateRange} />
          ) : (
            <Livechart2 symbol={symbol} dateRange={dateRange} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChartSwitcher;
