import React, { useState, useEffect } from 'react';
import { fetchCompanyPrice } from './services';
import { formatNumber, formatPercentage } from './Utils2';


const Overview = ({ symbol }) => {
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!symbol) return;

      setLoading(true);
      setError(null);
      try {
        const data = await fetchCompanyPrice(symbol);
        setPriceData(data);
      } catch (err) {
        console.error('Error fetching price data:', err);
        setError('Failed to load price data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol]);

  if (!symbol) {
    return (
      <div className="bg-white dark:bg-dark-card rounded-lg shadow-lg p-6">
        <div className="text-center text-gray-500 dark:text-gray-400">
          Enter a stock symbol to view overview
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="bg-white dark:bg-dark-card rounded-lg shadow-lg p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/3"></div>
          <div className="h-12 bg-gray-200 dark:bg-gray-700 rounded w-2/3"></div>
          <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white dark:bg-dark-card rounded-lg shadow-lg p-6">
        <div className="text-red-500 dark:text-red-400">
          {error}
        </div>
      </div>
    );
  }

  if (!priceData) return null;

  const isPositiveChange = priceData.changesPercentage >= 0;

  return (
    <div className="bg-white dark:bg-dark-card rounded-lg shadow-lg p-6">
      <div className="space-y-4">
        {/* Symbol and Price */}
        <div>
          <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
            {symbol}
          </h2>
          <div className="flex items-baseline space-x-2">
            <span className="text-3xl font-bold text-gray-900 dark:text-gray-100">
              ${priceData.price?.toFixed(2)}
            </span>
            <span className={`text-lg font-medium ${
              isPositiveChange ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
            }`}>
              {formatPercentage(priceData.changesPercentage)}
            </span>
          </div>
        </div>

        {/* Trading Info */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">Volume</p>
            <p className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {formatNumber(priceData.volume)}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">Avg Volume</p>
            <p className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {formatNumber(priceData.avgVolume)}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">Market Cap</p>
            <p className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {formatNumber(priceData.marketCap)}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">P/E Ratio</p>
            <p className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {priceData.pe?.toFixed(2) || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;