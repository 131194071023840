// Data formatting functions for charts
export const formatCustomStockData = (stockData) => {
    if (!Array.isArray(stockData) || stockData.length === 0) {
        console.warn('Invalid or empty stock data received');
        return [];
    }
    
    console.log('Formatting candlestick data:', stockData);
    
    const formattedData = stockData
        .filter(dataPoint => (
            dataPoint.date &&
            dataPoint.open != null &&
            dataPoint.high != null &&
            dataPoint.low != null &&
            dataPoint.close != null
        ))
        .map(dataPoint => {
            const date = new Date(dataPoint.date);
            return {
                x: date.getTime(),
                y: [
                    Number(dataPoint.open),
                    Number(dataPoint.high),
                    Number(dataPoint.low),
                    Number(dataPoint.close)
                ]
            };
        });

    const sortedData = formattedData.sort((a, b) => a.x - b.x);
    console.log('Formatted candlestick data:', sortedData);
    return sortedData;
};

export const formatCustomStockData3 = (stockData) => {
    if (!Array.isArray(stockData) || stockData.length === 0) {
        console.warn('Invalid or empty stock data received');
        return [];
    }
    
    console.log('Formatting line data:', stockData);
    
    const formattedData = stockData
        .filter(dataPoint => (
            dataPoint.date &&
            dataPoint.close != null
        ))
        .map(dataPoint => ({
            x: new Date(dataPoint.date).getTime(),
            y: Number(dataPoint.close)
        }));

    const sortedData = formattedData.sort((a, b) => a.x - b.x);
    console.log('Formatted line data:', sortedData);
    return sortedData;
};


export const formatBarData = (stockData) => {
    if (!Array.isArray(stockData) || stockData.length === 0) {
        console.warn('Invalid or empty stock data received');
        return [];
    }
    
    console.log('Formatting volume data:', stockData);
    
    const formattedData = stockData
        .filter(dataPoint => (
            dataPoint.date &&
            dataPoint.volume != null
        ))
        .map(dataPoint => ({
            x: new Date(dataPoint.date).getTime(),
            y: Number(dataPoint.volume)
        }));

    const sortedData = formattedData.sort((a, b) => a.x - b.x);
    console.log('Formatted volume data:', sortedData);
    return sortedData;
};


export const formatCompanyOutlook = (data) => {
    if (!data) return null;
    try {
        const { industry, exchange } = data;
        return { industry, exchange };
    } catch (error) {
        console.error('Error formatting company outlook:', error);
        return null;
    }
};


// Utility functions for formatting values
export const formatNumber = (num) => {
    if (!num && num !== 0) return 'N/A';
    
    try {
        const absNum = Math.abs(Number(num));
        if (absNum >= 1e12) {
            return (num / 1e12).toFixed(2) + 'T';
        } else if (absNum >= 1e9) {
            return (num / 1e9).toFixed(2) + 'B';
        } else if (absNum >= 1e6) {
            return (num / 1e6).toFixed(2) + 'M';
        } else if (absNum >= 1e3) {
            return (num / 1e3).toFixed(2) + 'K';
        }
        return num.toFixed(2);
    } catch (error) {
        console.error('Error formatting number:', error);
        return 'N/A';
    }
};

export const formatDate = (date) => {
    if (!date) return '';
    try {
        const d = new Date(date);
        if (isNaN(d.getTime())) return '';
        return d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    } catch (error) {
        console.error('Error formatting date:', error);
        return '';
    }
};

export const formatPercentage = (value) => {
    if (!value && value !== 0) return 'N/A';
    try {
        const num = Number(value);
        return `${num >= 0 ? '+' : ''}${num.toFixed(2)}%`;
    } catch (error) {
        console.error('Error formatting percentage:', error);
        return 'N/A';
    }
};

export const formatCurrency = (value, decimals = 2) => {
    if (!value && value !== 0) return 'N/A';
    try {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }).format(value);
    } catch (error) {
        console.error('Error formatting currency:', error);
        return 'N/A';
    }
};
