import { MoonIcon, SunIcon } from "@heroicons/react/solid";
import React, { useState, useEffect, useCallback } from "react";

const ThemeIcon = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [mounted, setMounted] = useState(false);

    // Define updateTheme first
    const updateTheme = useCallback((isDark) => {
        const root = document.documentElement;
        const transitionDuration = '200ms';

        // Add transition before changing theme
        root.style.transition = `background-color ${transitionDuration}, color ${transitionDuration}, border-color ${transitionDuration}`;

        if (isDark) {
            root.classList.add('dark');
            localStorage.setItem('theme', 'dark');
            root.style.colorScheme = 'dark';
        } else {
            root.classList.remove('dark');
            localStorage.setItem('theme', 'light');
            root.style.colorScheme = 'light';
        }

        // Remove transition after theme change
        setTimeout(() => {
            root.style.transition = '';
        }, 200);
    }, []);

    useEffect(() => {
        // Prevent theme flash by setting initial theme before mounting
        const savedTheme = localStorage.getItem('theme');
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const initialDarkMode = savedTheme ? savedTheme === 'dark' : systemPrefersDark;
        
        updateTheme(initialDarkMode);
        setDarkMode(initialDarkMode);
        setMounted(true);

        // Listen for system theme changes
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
            if (!localStorage.getItem('theme')) {
                setDarkMode(e.matches);
                updateTheme(e.matches);
            }
        };
        
        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, [updateTheme]);



    const toggleDarkMode = useCallback(() => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        updateTheme(newDarkMode);
    }, [darkMode, updateTheme]);

    // Prevent flash of incorrect theme
    if (!mounted) return null;

    return (
        <button 
            onClick={toggleDarkMode}
            className="
                rounded-lg 
                border border-gray-200 dark:border-gray-700 
                p-2.5
                shadow-lg 
                hover:shadow-xl 
                transition-all 
                duration-300 
                flex 
                items-center 
                space-x-2.5
                bg-white dark:bg-gray-800
                hover:bg-gray-50 dark:hover:bg-gray-700
                focus:outline-none focus:ring-2 focus:ring-purple-500 dark:focus:ring-purple-400
                active:scale-95
                select-none
            "
            aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
        > 
            {darkMode ? (
                <>
                    <SunIcon className="h-5 w-5 text-yellow-500" />
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                        Light Mode
                    </span>
                </>
            ) : (
                <>
                    <MoonIcon className="h-5 w-5 text-blue-600" />
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                        Dark Mode
                    </span>
                </>
            )}
        </button>
    );
};

export default ThemeIcon;