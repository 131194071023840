import React, { useEffect, useState } from 'react';
import { fetchDescription } from './services';

const Description = ({ symbol }) => {
    const [companyInfo, setCompanyInfo] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!symbol) return;
            
            setLoading(true);
            setError(null);
            try {
                const data = await fetchDescription(symbol);
                setCompanyInfo(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [symbol]);

    if (error) {
        return (
            <div className="text-red-500 dark:text-red-400">
                Error: {error.message}
            </div>
        );
    }

    if (loading) {
        return (
            <div className="flex items-center justify-center p-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600 dark:border-purple-400"></div>
            </div>
        );
    }

    if (!companyInfo) {
        return (
            <div className="text-gray-500 dark:text-gray-400">
                Enter a stock symbol to view company information
            </div>
        );
    }
    
    const getLimitedInfo = (description) => {
        const sentences = description.split('. ');
    
        if (sentences[0].length < 20 && sentences.length > 1) {
            // Capitalize the first letter of the second sentence
            let secondSentence = sentences[1];
            secondSentence = secondSentence.charAt(0).toUpperCase() + secondSentence.slice(1);
            
            // Find the index of the second dot in the second sentence
            const secondDotIndex = secondSentence.indexOf('.');
    
            if (secondDotIndex !== -1) {
                // Return up to the second dot in the second sentence
                return `${secondSentence.substring(0, secondDotIndex + 1)}.`;
            } else {
                // If no second dot found, return the whole second sentence
                return `${secondSentence}.`;
            }
        } else {
            
            return sentences[0] + '.';
        }
    };
    
    const limitedInfo = getLimitedInfo(companyInfo.description);
   


    return (
        <div className="flex items-start space-x-4 pr-3">
            <img 
                src={companyInfo.image} 
                alt={`${companyInfo.companyName} logo`} 
                className="w-20 h-20 bg-black py-1 rounded-md"
            />
            <div>
                <h1 className="text-xl font-bold pt-3 text-gray-900 dark:text-gray-100">
                    {companyInfo.companyName}
                </h1>
                <p className="text-lg text-gray-700 dark:text-gray-300">
                    {limitedInfo}
                </p>
            </div>
            <div>
                <p className="text-xl pt-3">
                    <span className="font-bold text-gray-900 dark:text-gray-100">Exchange:</span>{' '}
                    <span className="text-gray-700 dark:text-gray-300">{companyInfo.exchange}</span>
                </p>
                <p className="text-xl">
                    <span className="font-bold text-gray-900 dark:text-gray-100">Sector:</span>{' '}
                    <span className="text-gray-700 dark:text-gray-300">{companyInfo.sector}</span>
                </p>
            </div>
        </div>
    );
};

export default Description;
