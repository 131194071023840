
import React, { useState, useEffect } from "react";
import Search from "./Search";
import ThemeIcon from "./Background";
import { getMarketStatus } from "./services";

const Header = ({ setSymbol }) => {
    const [marketStatus, setMarketStatus] = useState({ isOpen: false, message: 'Loading...' });
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        // Update market status every minute
        const fetchMarketStatus = async () => {
            try {
                const status = await getMarketStatus();
                setMarketStatus(status);
            } catch (error) {
                console.error('Error fetching market status:', error);
                setMarketStatus({ isOpen: false, message: 'Status unavailable' });
            }
        };

        fetchMarketStatus();
        const marketInterval = setInterval(fetchMarketStatus, 60000); // Every minute

        // Update current time every second
        const updateTime = () => {
            const now = new Date();
            setCurrentTime(now.toLocaleString('en-US', {
                weekday: 'short',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZoneName: 'short'
            }));
        };

        updateTime();
        const timeInterval = setInterval(updateTime, 1000);

        return () => {
            clearInterval(marketInterval);
            clearInterval(timeInterval);
        };
    }, []);

    return (
        <div className="flex items-center justify-between w-full px-6 py-3">
            <ThemeIcon />
            
            <div className="flex-1 flex justify-center max-w-2xl mx-auto px-4">
                <Search setSymbol={setSymbol} />
            </div>

            <div className="flex items-center space-x-6">
                <div className="flex flex-col items-end space-y-1">
                    <div className="flex items-center space-x-2">
                        <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
                            Market
                        </span>
                        <span className={`
                            inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                            ${marketStatus.isOpen 
                                ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                            }
                        `}>
                            {marketStatus.isOpen ? 'Open' : 'Closed'}
                        </span>
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400 font-medium">
                        {currentTime}
                    </div>
                </div>
            </div>
        </div>
    );
};
  

export default Header;