import React, { useState } from "react";
import { exampleCompanyDetails } from "../constants/example";
import Header from "./Header";
import Overview from "./Overview";
import CardFWarren from "./warren_peter";
import ChartSwitcher from "./chartswitch";
import CompanyOutlookTable from "./livechartdescription";
import Description from "./Description";
import PeerComparisonTable from "./PeerComparisonTable";

const Dashboard = () => {
  const [symbol, setSymbol] = useState("");

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-bg transition-colors duration-200">
      {/* Header Section */}
      <Header setSymbol={setSymbol} />

      {/* Main Content */}
      <main className="container mx-auto px-4 py-6 space-y-6 font-afacad">
        {/* Title and Description Section */}
        <div className="space-y-6">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-gray-100 transition-colors duration-200">
            {symbol || exampleCompanyDetails.name}
          </h1>
          <Description symbol={symbol} />
        </div>

        {/* Analysis Section */}
        <div className="space-y-6">
          <CardFWarren symbol={symbol} />
        </div>

        {/* Chart and Overview Section */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
          <div className="xl:col-span-2">
            <ChartSwitcher symbol={symbol} />
          </div>
          <div className="xl:col-span-1 space-y-6">
            <Overview symbol={symbol} />
            <CompanyOutlookTable symbol={symbol} />
          </div>
        </div>

        {/* Peer Comparison Section */}
        <div className="mt-6">
          <PeerComparisonTable symbol={symbol} />
        </div>
      </main>
    </div>
  );
};

export default Dashboard;