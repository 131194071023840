import './App.css';
import Dashboard from './components/Dashboard';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Initialize theme
    const initializeTheme = () => {
      // Check if theme is stored in localStorage
      const savedTheme = localStorage.getItem('theme');
      
      if (savedTheme) {
        // Use saved preference
        document.documentElement.classList.toggle('dark', savedTheme === 'dark');
      } else {
        // Check system preference
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        document.documentElement.classList.toggle('dark', prefersDark);
      }
    };

    initializeTheme();

    // Listen for system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      if (!localStorage.getItem('theme')) {
        document.documentElement.classList.toggle('dark', e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-dark-bg transition-colors duration-200">
      <Dashboard />
    </div>
  );
}

export default App;
