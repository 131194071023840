const API_KEYS = [
  process.env.REACT_APP_API_KEY || "oGNfp3JAE7yA27rrva4WyTSE3p2A7gKa",
  process.env.REACT_APP_API_KEY_BACKUP || "MZNUxALEyarHKD7VgwIFq9BntSNq9MjA"
];

let currentKeyIndex = 0;

const getNextApiKey = () => {
  currentKeyIndex = (currentKeyIndex + 1) % API_KEYS.length;
  return API_KEYS[currentKeyIndex];
};

const handleApiResponse = async (response) => {
  if (response.status === 429) {
    // Try with the next API key
    const nextKey = getNextApiKey();
    // Retry the request with the new key
    const retryUrl = response.url.replace(/apikey=[^&]+/, `apikey=${nextKey}`);
    const retryResponse = await fetch(retryUrl);
    return retryResponse.json();
  }
  return response.json();
};

// Utility functions
const fetchWithRetry = async (url, retries = 3) => {
  let lastError;
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetch(url);
      if (!response.ok && response.status !== 429) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await handleApiResponse(response);
      if (data.hasOwnProperty('Error Message')) {
        throw new Error(data['Error Message']);
      }
      return data;
    } catch (error) {
      console.error(`Attempt ${i + 1} failed:`, error);
      lastError = error;
      if (i < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
  }
  throw lastError;
};

const getStartDate = (timeframe) => {
  const now = new Date();
  let startDate = new Date(now);

  switch (timeframe) {
    case '1D': startDate.setDate(now.getDate() - 1); break;
    case '5D': startDate.setDate(now.getDate() - 5); break;
    case '1M': startDate.setMonth(now.getMonth() - 1); break;
    case '6M': startDate.setMonth(now.getMonth() - 6); break;
    case 'YTD': startDate = new Date(now.getFullYear(), 0, 1); break;
    case '1Y': startDate.setFullYear(now.getFullYear() - 1); break;
    case '5Y': startDate.setFullYear(now.getFullYear() - 5); break;
    case 'MAX': startDate.setFullYear(now.getFullYear() - 20); break;
    default: startDate.setDate(now.getDate() - 5);
  }

  return startDate;
};



/* eslint-disable no-unused-vars */
const fetchMarketHolidays = async (exchange) => {
  const url = `https://financialmodelingprep.com/api/v3/market-holidays?exchange=${exchange}&apikey=${API_KEYS[currentKeyIndex]}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return Array.isArray(data) ? data.map(holiday => holiday.date) : [];
  } catch (error) {
    console.error('Error fetching market holidays:', error);
    return [];
  }
};

const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6; // Sunday = 0, Saturday = 6
};
/* eslint-enable no-unused-vars */



// Fetch historical stock data
export const fetchCustomStockData = async (symbol, timeframe = '5D') => {
  if (!symbol) {
    throw new Error('Symbol is required');
  }

  try {
    const startDate = getStartDate(timeframe);
    const endDate = new Date();
    
    console.log(`Fetching data for ${symbol} from ${startDate.toISOString()} to ${endDate.toISOString()}`);
    
    const url = `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?from=${startDate.toISOString().split('T')[0]}&to=${endDate.toISOString().split('T')[0]}&apikey=${API_KEYS[currentKeyIndex]}`;
    const data = await fetchWithRetry(url);
    
    if (!data.historical || data.historical.length === 0) {
      throw new Error('No historical data available');
    }

    // Sort data by date in ascending order and map to required format
    return data.historical
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map(item => ({
        date: item.date,
        open: parseFloat(item.open),
        high: parseFloat(item.high),
        low: parseFloat(item.low),
        close: parseFloat(item.close),
        volume: parseInt(item.volume, 10)
      }));

  } catch (error) {
    console.error('Error fetching stock data:', error);
    throw new Error(error.message || 'Failed to fetch stock data');
  }
};



export const fetchStockPeers = async (symbol) => {
  try {
    // Get company sector from FMP
    const profileUrl = `https://financialmodelingprep.com/api/v3/profile/${symbol}?apikey=${API_KEYS[currentKeyIndex]}`;
    const profileData = await fetchWithRetry(profileUrl);
    
    if (!profileData?.[0]?.sector) {
      throw new Error('Company sector not found');
    }

    // Get sector peers
    const sectorUrl = `https://financialmodelingprep.com/api/v3/stock-screener?sector=${encodeURIComponent(profileData[0].sector)}&limit=5&apikey=${API_KEYS[currentKeyIndex]}`;
    const sectorData = await fetchWithRetry(sectorUrl);
    
    const peerSymbols = [
      symbol,
      ...sectorData
        .filter(company => company.symbol !== symbol)
        .slice(0, 4)
        .map(company => company.symbol)
    ];

    // Get detailed metrics for each peer
    return await Promise.all(peerSymbols.map(async (peerSymbol) => {
      try {
        const quoteUrl = `https://financialmodelingprep.com/api/v3/quote/${peerSymbol}?apikey=${API_KEYS[currentKeyIndex]}`;
        const ratiosUrl = `https://financialmodelingprep.com/api/v3/ratios-ttm/${peerSymbol}?apikey=${API_KEYS[currentKeyIndex]}`;
        const metricsUrl = `https://financialmodelingprep.com/api/v3/key-metrics-ttm/${peerSymbol}?apikey=${API_KEYS[currentKeyIndex]}`;

        const [quoteData, ratiosData, metricsData] = await Promise.all([
          fetchWithRetry(quoteUrl),
          fetchWithRetry(ratiosUrl),
          fetchWithRetry(metricsUrl)
        ]);

        const quote = quoteData[0] || {};
        const ratios = ratiosData[0] || {};
        const metrics = metricsData[0] || {};

        return {
          symbol: peerSymbol,
          name: quote.name || peerSymbol,
          price: quote.price?.toFixed(2) || 'N/A',
          pe: ratios.peRatioTTM?.toFixed(2) || 'N/A',
          marketCap: (quote.marketCap / 10000000).toFixed(2) || 'N/A',
          dividendYield: (ratios.dividendYieldTTM * 100)?.toFixed(2) || 'N/A',
          netProfit: (metrics.netIncomePerShareTTM * quote.sharesOutstanding / 10000000).toFixed(2) || 'N/A',
          qtrProfitVar: (ratios.netProfitMarginTTM * 100)?.toFixed(2) || 'N/A',
          salesQtr: (metrics.revenueTTM / 10000000).toFixed(2) || 'N/A',
          qtrSalesVar: (ratios.operatingIncomeGrowthTTM * 100)?.toFixed(2) || 'N/A',
          roce: (ratios.returnOnCapitalEmployedTTM * 100)?.toFixed(2) || 'N/A'
        };
      } catch (error) {
        console.error(`Error fetching data for ${peerSymbol}:`, error);
        return {
          symbol: peerSymbol,
          name: peerSymbol,
          price: 'N/A',
          pe: 'N/A',
          marketCap: 'N/A',
          dividendYield: 'N/A',
          netProfit: 'N/A',
          qtrProfitVar: 'N/A',
          salesQtr: 'N/A',
          qtrSalesVar: 'N/A',
          roce: 'N/A'
        };
      }
    }));
  } catch (error) {
    console.error('Error fetching peer data:', error);
    throw error;
  }
};



// Add search function for stock symbols
export const searchSymbols = async (query) => {
    if (!query || query.length < 1) return [];

    try {
        const url = `https://financialmodelingprep.com/api/v3/search?query=${encodeURIComponent(query)}&limit=10&apikey=${API_KEYS[currentKeyIndex]}`;
        const data = await fetchWithRetry(url);
        
        return data.map(item => ({
            symbol: item.symbol,
            name: item.name,
            currency: item.currency,
            stockExchange: item.stockExchange,
            exchangeShortName: item.exchangeShortName || item.exchange
        }));
    } catch (error) {
        console.error('Error searching symbols:', error);
        return [];
    }
};

// Add market status check
export const getMarketStatus = async () => {
    try {
        const url = `https://financialmodelingprep.com/api/v3/market-hours?apikey=${API_KEYS[currentKeyIndex]}`;
        const data = await fetchWithRetry(url);
        
        if (!data || data.length === 0) {
            return { isOpen: false, message: 'Market status unavailable' };
        }

        const marketData = data[0];
        return {
            isOpen: marketData.isTheStockMarketOpen,
            message: marketData.isTheStockMarketOpen ? 'Market Open' : 'Market Closed',
            nextOpenDate: marketData.nextOpenDate,
            nextCloseDate: marketData.nextCloseDate
        };
    } catch (error) {
        console.error('Error checking market status:', error);
        return { isOpen: false, message: 'Status unavailable' };
    }
};

// Add function to get market hours
export const getMarketHours = async () => {
    try {
        const url = `https://financialmodelingprep.com/api/v3/market-hours?apikey=${API_KEYS[currentKeyIndex]}`;
        const data = await fetchWithRetry(url);
        
        if (!data || data.length === 0) {
            return null;
        }

        return {
            openingHour: data[0].openingHour,
            closingHour: data[0].closingHour,
            timezone: data[0].timezone
        };
    } catch (error) {
        console.error('Error fetching market hours:', error);
        return null;
    }
};

// Other functions (fetchCompanyOutlook, fetchCompanyPrice, fetchDescription) remain unchanged
// Fetch company overview
export const fetchCompanyOutlook = async (symbol) => {
  if (!symbol) return null;
  
  try {
    const data = await fetchWithRetry(`https://financialmodelingprep.com/api/v3/profile/${symbol}?apikey=${API_KEYS[currentKeyIndex]}`);
    return data?.[0] || null;
  } catch (error) {
    console.error('Error fetching company outlook:', error);
    throw error;
  }
};



// Fetch current price and related metrics
export const fetchCompanyPrice = async (symbol) => {
  if (!symbol) return null;

  try {
    const data = await fetchWithRetry(`https://financialmodelingprep.com/api/v3/quote/${symbol}?apikey=${API_KEYS[currentKeyIndex]}`);
    if (!data?.[0]) return null;

    const quote = data[0];
    return {
      price: quote.price,
      changesPercentage: quote.changesPercentage,
      change: quote.change,
      dayLow: quote.dayLow,
      dayHigh: quote.dayHigh,
      yearHigh: quote.yearHigh,
      yearLow: quote.yearLow,
      marketCap: quote.marketCap,
      volume: quote.volume,
      avgVolume: quote.avgVolume,
      open: quote.open,
      previousClose: quote.previousClose,
      eps: quote.eps,
      pe: quote.pe
    };
  } catch (error) {
    console.error('Error fetching price data:', error);
    throw error;
  }
};



// Fetch company description and basic info
export const fetchDescription = async (symbol) => {
  if (!symbol) return null;

  try {
    const data = await fetchWithRetry(`https://financialmodelingprep.com/api/v3/profile/${symbol}?apikey=${API_KEYS[currentKeyIndex]}`);
    if (!data?.[0]) return null;
    
    const { image, companyName, description, exchange, sector } = data[0];
    return { image, companyName, description, exchange, sector };
  } catch (error) {
    console.error('Error fetching description:', error);
    throw error;
  }
};

