import React, { useState, useEffect } from 'react';
import { fetchCompanyOutlook, fetchCompanyPrice } from './services';
import TableRow from './tablerow';

const formatNumber = (num) => {
  if (num === null || num === undefined) return 'N/A';
  
  try {
    const numValue = Number(num);
    if (isNaN(numValue)) return 'N/A';
    
    if (numValue >= 1e12) {
      return `${(numValue / 1e12).toFixed(2)}T`;
    } else if (numValue >= 1e9) {
      return `${(numValue / 1e9).toFixed(2)}B`;
    } else if (numValue >= 1e6) {
      return `${(numValue / 1e6).toFixed(2)}M`;
    } else if (numValue >= 1e3) {
      return `${(numValue / 1e3).toFixed(2)}K`;
    }
    return numValue.toLocaleString();
  } catch (error) {
    console.error('Error formatting number:', error);
    return 'N/A';
  }
};

const formatPrice = (price) => {
  if (price === null || price === undefined) return 'N/A';
  try {
    const numValue = Number(price);
    return isNaN(numValue) ? 'N/A' : `$${numValue.toFixed(2)}`;
  } catch (error) {
    return 'N/A';
  }
};

const CompanyOutlookTable = ({ symbol }) => {
  const [companyOutlook, setCompanyOutlook] = useState(null);
  const [priceInfo, setPriceInfo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!symbol) return;
      
      setLoading(true);
      setError(null);
      try {
        const [outlookData, priceData] = await Promise.all([
          fetchCompanyOutlook(symbol),
          fetchCompanyPrice(symbol)
        ]);
        setCompanyOutlook(outlookData);
        setPriceInfo(priceData);
      } catch (err) {
        setError(err.message || 'Failed to load data');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [symbol]);

  if (!symbol) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
        <div className="text-gray-500 dark:text-gray-400 text-center">
          Enter a stock symbol to view details
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
        <div className="animate-pulse space-y-4">
          {[...Array(8)].map((_, i) => (
            <div key={i} className="flex justify-between items-center">
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4"></div>
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/3"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
        <div className="text-red-500 dark:text-red-400 text-center">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
      {companyOutlook && priceInfo ? (
        <table className="w-full text-sm md:text-base text-left">
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            <TableRow name="Open" value={formatPrice(priceInfo.open)} index={14} />
            <TableRow name="Previous Close" value={formatPrice(priceInfo.previousClose)} index={15} />
            <TableRow name="EPS" value={priceInfo.eps ?? 'N/A'} index={16} />
            <TableRow name="PE" value={priceInfo.pe ?? 'N/A'} index={17} />
            <TableRow name="Volume" value={formatNumber(priceInfo.volume)} index={12} />
            <TableRow name="Day Low" value={formatPrice(priceInfo.dayLow)} index={4} />
            <TableRow name="Day High" value={formatPrice(priceInfo.dayHigh)} index={5} />
            <TableRow name="Year Low" value={formatPrice(priceInfo.yearLow)} index={6} />
            <TableRow name="Year High" value={formatPrice(priceInfo.yearHigh)} index={7} />
            <TableRow name="Market Cap" value={formatNumber(priceInfo.marketCap)} index={8} />
            <TableRow name="Average Volume" value={formatNumber(priceInfo.avgVolume)} index={13} />
          </tbody>
        </table>
      ) : (
        <div className="p-4 text-gray-500 dark:text-gray-400 text-center">
          No data available
        </div>
      )}
    </div>
  );
};

export default CompanyOutlookTable;
