import React, { useState, useEffect, useCallback } from "react";
import { SearchIcon, XIcon } from "@heroicons/react/solid";
import SearchResults from "./SearchResults";
import { searchSymbols } from "./services";
import { debounce } from 'lodash';

const Search = ({ setSymbol }) => {
    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Create debounced search function with proper dependencies
    const debouncedSearch = useCallback(
        debounce((query) => {
            const searchSymbolsAsync = async () => {
                if (!query.trim()) {
                    setResults([]);
                    return;
                }

                setLoading(true);
                setError(null);
                try {
                    const searchResults = await searchSymbols(query);
                    setResults(searchResults);
                } catch (err) {
                    console.error('Search error:', err);
                    setError('Failed to search symbols');
                } finally {
                    setLoading(false);
                }
            };
            searchSymbolsAsync();
        }, 300),
        [setResults, setLoading, setError] // Add state setters as dependencies
    );

    useEffect(() => {
        debouncedSearch(input);
        return () => debouncedSearch.cancel();
    }, [input, debouncedSearch]);

    const clear = () => {
        setInput("");
        setResults([]);
        setError(null);
    };

    const handleSymbolSelect = (symbol) => {
        setSymbol(symbol);
        clear();
    };

    const handleSubmit = () => {
        if (!input.trim()) return;
        setSymbol(input.toUpperCase());
        clear();
    };

    return (
        <div className="relative w-96">
            <div className="flex items-center bg-white dark:bg-gray-800 border-2 border-neutral-200 dark:border-gray-700 rounded-md shadow-sm">
                <input
                    type="text"
                    value={input}
                    className="w-full px-4 py-2 text-gray-900 dark:text-gray-100 bg-transparent focus:outline-none rounded-l-md"
                    placeholder="Search stock symbol..."
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            handleSubmit();
                        }
                    }}
            />
                {input && (
                    <button 
                        onClick={clear}
                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors duration-200"
                    >
                        <XIcon className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                    </button>
                )}
                <button
                    onClick={handleSubmit}
                    className={`h-9 w-10 rounded-r-md flex justify-center items-center m-1 transition-colors duration-200 ${
                        input.trim() 
                            ? 'bg-purple-600 hover:bg-purple-700 dark:bg-purple-700 dark:hover:bg-purple-800' 
                            : 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
                    }`}
                    disabled={!input.trim()}
                >
                    {loading ? (
                        <div className="h-4 w-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    ) : (
                        <SearchIcon className="h-4 w-4 text-white" />
                    )}
                </button>
            </div>
            {input && (error || results.length > 0) && (
                <div className="absolute w-full mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg z-50">
                    {error ? (
                        <div className="p-4 text-sm text-red-600 dark:text-red-400">
                            {error}
                        </div>
                    ) : (
                        <SearchResults 
                            results={results} 
                            onSelect={handleSymbolSelect}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default Search;
