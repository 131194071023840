import React, { useState, useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { fetchCustomStockData } from './services';
import { formatCustomStockData3, formatBarData, formatNumber } from './Utils2';

const Livechart2 = ({ symbol, dateRange }) => {
  const [stockData, setStockData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));

  // Listen for dark mode changes
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          setIsDarkMode(document.documentElement.classList.contains('dark'));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const fetchStockData = async () => {
      if (!symbol) return;
      
      setLoading(true);
      setError(null);
      try {
        console.log(`[LineChart] Fetching data for ${symbol} with range ${dateRange}`);
        const data = await fetchCustomStockData(symbol, dateRange);
        console.log('[LineChart] Raw data:', data);
        if (!data || data.length === 0) {
          throw new Error('No data available');
        }
        setStockData(data);
      } catch (error) {
        console.error('[LineChart] Error:', error);
        setError(error.message || 'Failed to load chart data');
      } finally {
        setLoading(false);
      }
    };

    fetchStockData();
  }, [symbol, dateRange]);

  const lineData = useMemo(() => {
    if (!stockData.length) return [];
    console.log('[LineChart] Formatting price data:', stockData);
    const formatted = formatCustomStockData3(stockData);
    console.log('[LineChart] Formatted price data:', formatted);
    return formatted;
  }, [stockData]);

  const barData = useMemo(() => {
    if (!stockData.length) return [];
    console.log('[LineChart] Formatting volume data:', stockData);
    const formatted = formatBarData(stockData);
    console.log('[LineChart] Formatted volume data:', formatted);
    return formatted;
  }, [stockData]);

  const options = {
    chart: {
      type: 'line',
      height: 350,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800
      },
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true
        },
        autoSelected: 'zoom'
      },
      background: 'transparent',
      foreColor: isDarkMode ? '#F3F4F6' : '#111827'
    },
    theme: {
      mode: isDarkMode ? 'dark' : 'light'
    },
    stroke: {
      width: [3, 1],
      curve: 'smooth'
    },
    colors: ['#6366F1', isDarkMode ? '#64748B' : '#94A3B8'],
    fill: {
      type: ['solid', 'gradient'],
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        opacityFrom: 0.8,
        opacityTo: 0.2,
        colorStops: [
          {
            offset: 0,
            color: isDarkMode ? '#64748B' : '#94A3B8',
            opacity: 0.4
          },
          {
            offset: 100,
            color: isDarkMode ? '#64748B' : '#94A3B8',
            opacity: 0.1
          }
        ]
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '70%',
        borderRadius: 2
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      hover: {
        size: 5
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        style: {
          colors: isDarkMode ? '#F3F4F6' : '#666',
          fontSize: '12px'
        }
      },
      axisBorder: {
        color: isDarkMode ? '#374151' : '#E5E7EB'
      },
      axisTicks: {
        color: isDarkMode ? '#374151' : '#E5E7EB'
      }
    },
    yaxis: [
      {
        title: {
          text: 'Price ($)',
          style: {
            color: isDarkMode ? '#F3F4F6' : '#6366F1'
          }
        },
        labels: {
          formatter: function(value) {
            return '$' + value.toFixed(2);
          },
          style: {
            colors: isDarkMode ? '#F3F4F6' : '#666'
          }
        }
      },
      {
        opposite: true,
        title: {
          text: 'Volume',
          style: {
            color: isDarkMode ? '#94A3B8' : '#64748B'
          }
        },
        labels: {
          formatter: function(value) {
            return formatNumber(value);
          },
          style: {
            colors: isDarkMode ? '#F3F4F6' : '#666'
          }
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      theme: isDarkMode ? 'dark' : 'light',
      y: [{
        formatter: function(value) {
          return '$' + value.toFixed(2);
        }
      }, {
        formatter: function(value) {
          return formatNumber(value) + ' shares';
        }
      }]
    },
    title: {
      text: `${symbol} Stock Price & Volume`,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: isDarkMode ? '#F3F4F6' : '#263238'
      }
    },
    grid: {
      borderColor: isDarkMode ? '#374151' : '#f1f1f1',
      strokeDashArray: 3,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    }
  };

  const series = [
    {
      name: 'Price',
      type: 'line',
      data: lineData,
    },
    {
      name: 'Volume',
      type: 'bar',
      data: barData,
    },
  ];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600 dark:border-purple-400"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <div className="text-center">
          <p className="font-medium text-red-500 dark:text-red-400">{error}</p>
          <p className="text-sm mt-2 text-gray-500 dark:text-gray-400">Please try again later</p>
        </div>
      </div>
    );
  }

  if (!symbol) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <span className="text-gray-500 dark:text-gray-400">Enter a stock symbol to view chart</span>
      </div>
    );
  }

  if (!lineData.length) {
    return (
      <div className="flex items-center justify-center h-[350px] bg-white dark:bg-gray-800">
        <span className="text-gray-500 dark:text-gray-400">No data available for this time range</span>
      </div>
    );
  }

  return (
    <div className="chart-container bg-white dark:bg-gray-800 rounded-lg overflow-hidden">
      <ReactApexChart 
        options={options} 
        series={series} 
        type="line" 
        height={350}
      />
    </div>
  );
};

export default Livechart2;