import React from 'react';
import PropTypes from 'prop-types';

const InfoCardWarren = ({ 
  title, 
  conditionsMet = 0, 
  totalConditions = 1, 
  percentage = 0,
  loading = false 
}) => {
  const isPositive = percentage >= 50;

  if (loading) {
    return (
      <div className="p-6 bg-white dark:bg-dark-card rounded-lg shadow">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
          <div className="space-y-2">
            <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded"></div>
            <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-1/3"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white dark:bg-dark-card rounded-lg shadow transition-colors duration-200">
      <div className="space-y-4">
        <span className="text-sm font-medium text-gray-500 dark:text-gray-400 uppercase">
          {title}
        </span>
        
        <div className="flex items-center justify-between">
          <div className="text-3xl font-bold text-gray-900 dark:text-gray-100">
            {conditionsMet}/{totalConditions}
          </div>
          
          <div className={`
            px-3 py-1 rounded-full text-sm font-medium
            ${isPositive 
              ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
              : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
            }
          `}>
            {percentage.toFixed(1)}%
          </div>
        </div>

        <div className="relative pt-1">
          <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-200 dark:bg-gray-700">
            <div 
              style={{ width: `${percentage}%` }}
              className={`
                shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center
                transition-all duration-500 ease-out
                ${isPositive 
                  ? 'bg-green-500 dark:bg-green-600'
                  : 'bg-red-500 dark:bg-red-600'
                }
              `}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

InfoCardWarren.propTypes = {
  title: PropTypes.string.isRequired,
  conditionsMet: PropTypes.number,
  totalConditions: PropTypes.number,
  percentage: PropTypes.number,
  loading: PropTypes.bool
};

export default InfoCardWarren;