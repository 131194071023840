import React from "react";

const SearchResults = ({ results, onSelect }) => {
    if (!results.length) {
        return (
            <div className="p-4 text-sm text-gray-500 dark:text-gray-400">
                No results found
            </div>
        );
    }

    return (
        <ul className="max-h-96 overflow-y-auto divide-y divide-gray-200 dark:divide-gray-700">
            {results.map((item) => (
                <li 
                    key={item.symbol} 
                    className="p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-200"
                    onClick={() => onSelect(item.symbol)}
                >
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">
                                {item.symbol}
                            </h3>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                {item.name}
                            </p>
                        </div>
                        <div className="flex flex-col items-end space-y-1">
                            <span className="px-2 py-1 text-xs font-medium text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-gray-800 rounded-md">
                                {item.exchangeShortName}
                            </span>
                            {item.currency && (
                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                    {item.currency}
                                </span>
                            )}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default SearchResults;
