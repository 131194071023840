// TableRow.js

import React from 'react';

const TableRow = ({ name, value, index }) => {
  const isOdd = index % 2 === 0; // Check if index is odd or even for alternating styles

  return (
    <tr className={`
      border-b dark:border-gray-700 transition-colors duration-200
      ${isOdd ? 'bg-gray-50 dark:bg-gray-800/50' : 'bg-white dark:bg-gray-800'}
    `}>
      <th 
        scope="row" 
        className="
          px-4 py-3 
          text-sm font-medium 
          text-gray-900 dark:text-gray-200 
          whitespace-nowrap
        "
      >
        {name}
      </th>
      <td 
        className="
          px-4 py-3 
          text-sm 
          text-gray-700 dark:text-gray-300 
          text-right
        "
      >
        {value}
      </td>
    </tr>
  );
}

export default TableRow;
